import {loadPluginCss} from 'app/plugins/sdk';
import {GanttPanelCtrl} from './gantt-panel';

loadPluginCss({
    dark: 'plugins/grafana-gantt-panel/css/gantt-panel-dark.css',
    light: 'plugins/grafana-gantt-panel/css/gantt-panel-light.css'
});

export {
  GanttPanelCtrl as PanelCtrl
};
